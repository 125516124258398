
.easyedit_blocks {

    display: flex;
    justify-content: space-between;

    .easyedit_block {
        width:30%;       

        .easyedit_section {
            margin-bottom:2em;
            ul {
                margin-top:.5em;
                li {
                    cursor:pointer;
                    background-color: #ddd;
                    margin-bottom: .75em;
                    padding: .25em .75em;    
                    box-shadow: 1px 1px 2px #888;

                    &:hover {
                       background-color: #ffe2aa;
                    }

                    img {
                        padding-right: .35em;
                    }
                    span, img {
                        display:inline-block;
                        vertical-align:middle;
                    }
                }
            }
        }
    }
}